// src/routes/SomePage.js
import React from 'react';
import Ziliao from '../components/Ziliao';

const ZiliaoPage = () => {
  return (
    <div>
      {/* 页面的其他内容 */}
      <Ziliao />
    </div>
  );
};

export default ZiliaoPage;
