import dva from 'dva';
import './index.css';
import barrages from './models/barrages';

// 1. 初始化
const app = dva();

// 2. Model
const models = require.context('./models', true, /\.js$/);
models.keys().forEach((key) => {
  app.model(models(key).default);
});

// 3. Router
app.router(require('../src/router').default);

// 4. 启动应用
app.start('#root');
